<template>
  <ModalBgWrapper :open="props.open" @close="onCloseDialog">
    <div
      class="relative box-border grid w-full grid-cols-[1fr_auto_1fr] justify-items-center gap-6 bg-black/50 px-5 pt-5"
    >
      <div
        class="col-start-2 box-border grid w-[160px] grid-cols-1 grid-rows-1 items-end justify-items-center"
      >
        <img
          class="order-3 col-start-1 row-start-1 w-full"
          src="/images/approve-asset-modal-wallet-front.svg"
          alt=""
        />
        <img
          class="order-2 col-start-1 row-start-1 mb-3 aspect-square w-3/4 rounded-full"
          src="/tokens/eth.png"
          alt=""
        />
        <img
          class="order-1 col-start-1 row-start-1 w-full"
          src="/images/approve-asset-modal-wallet-back.svg"
          alt=""
        />
      </div>
      <div class="text-lg">
        <a
          class="flex cursor-pointer rounded-lg bg-[#FFFFFF14] p-1.5 hover:bg-[#FFFFFF25]"
          @click="onCloseDialog"
        >
          <Icon icon="iconamoon:sign-times" />
        </a>
      </div>
    </div>
    <div class="flex flex-col bg-[#282828] p-6">
      <div class="text-2xl">
        Approve assets to
        <br />
        continue
      </div>
      <div class="mt-2.5 text-xs text-white/50">
        Complete the approval in your wallet to
        <br />
        proceed with the transaction.
      </div>
      <div class="my-6 flex items-center justify-between text-xs text-white/50">
        <span>Transaction Fee</span>
        <span class="flex items-center gap-[0.5em]">
          <template v-if="props.fee">
            <span class="text-sm text-white">$ {{ toPrice(props.fee, 'USD') }}</span>
            <Icon icon="ic:outline-info" />
          </template>
          <template v-else>
            <span class="text-xs text-white">Missing estimation</span>
            <Icon icon="material-symbols:warning" />
          </template>
        </span>
      </div>
      <button
        :class="[
          'box-border w-full cursor-pointer rounded-xl bg-[#C99D29] bg-opacity-10 px-5 py-3 text-center text-sm font-bold text-[#C99D29] hover:bg-opacity-20',
          { 'opacity-60': props.loading },
        ]"
        :disabled="props.loading"
        @click="onConfirm"
      >
        {{ props.loading ? 'Confirming...' : 'Confirm' }}
      </button>
    </div>
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { toPrice } from '~/utils/numbers'

const props = defineProps<{
  // TODO pass asset to approve
  open: boolean
  loading: boolean
  fee: number | null
}>()

const emits = defineEmits<{
  close: []
  confirm: []
}>()

const onCloseDialog = () => {
  emits('close')
}

const onConfirm = () => {
  emits('confirm')
}
</script>
